<template>
  <el-container>
    <!--网页头部开始-->
    <el-header role="banner">
      <div class="header-content">
        <div class="logo">
          <h1>
            <img src="@/assets/images/logo.png" alt="新翼天空" />
          </h1>
        </div>
        <div class="slogan">
          新翼天空，锻造科技之翼，精雕视障者所需的理想软件
        </div>
      </div>
    </el-header>
    <!--网页头部结束-->

    <!--网页主体开始-->
    <el-main role="main">
      <div class="main-content">
        <h2>原创软件</h2>
        <el-collapse v-model="activateNames" accordion>
          <el-collapse-item name="XYMouseSimulator">
            <template #title>
              <h3>新翼鼠标朗读器</h3>
            </template>
            <p>
              新翼鼠标朗读器是一款为低视力用户设计的辅助软件。安装后，当移动鼠标指针时，软件会通过语音播报指针下的对象内容。例如，当鼠标指针移动到桌面上的“此电脑”图标时，软件将语音播报“此电脑”。
            </p>
            <p>
              新翼鼠标朗读器可以被视为是一款轻量级的读屏软件，专注于通过鼠标操作进行语音朗读，用户无需改变已有的交互习惯，也无需记忆复杂的读屏热键，即可轻松上手，辅助提升电脑日常操作效率。
            </p>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1qXiCUng4nyKg88FYzqtTtA?pwd=2sdd"
                >点此百度网盘下载：新翼鼠标朗读器</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="XinyiOCR">
            <template #title>
              <h3>新翼OCR（NVDA读屏插件）</h3>
            </template>
            <p>
              这是一个提供离线和在线OCR识别的NVDA插件。离线识别基于PaddleOCR_json组件而开发。
            </p>
            <p>
              本插件目前提供PaddleOCR_json的离线OCR识别引擎和在线百度通用版、高精度版识别引擎，安装插件后会自动安装PaddleOCR_json组件。后续会逐步接入更多的其他离线、在线OCR识别引擎。
            </p>
            <p>
              此外，本插件还提供图像描述功能（目前已接入讯飞图片理解能力），可针对图片内容进行识别。
            </p>
            <p>
              此项目已在github开源，<a
                href="https://github.com/huaiyinfeilong/xyOCR"
                target="_blank"
                >https://github.com/huaiyinfeilong/xyOCR</a
              >目前已实现简体中文、俄语、英语本地化。
            </p>
            <p>插件优点：</p>
            <ul>
              <li>
                识别速度快，离线识别大约100ms左右，实际情况因计算机性能配置而异。
              </li>
              <li>强悍的离线识别准确度，媲美在线识别。</li>
              <li>识别结果文字坐标精准，结果文字点击响应准确。</li>
            </ul>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1-Ng2QCyHVKQxWjUB9i6B3A?pwd=ihw3"
                >点此百度网盘下载：新翼OCR（NVDA读屏插件）</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="baiduTranslation">
            <template #title>
              <h3>百度翻译（NVDA读屏插件）</h3>
            </template>
            <p>
              一个通过百度翻译服务实现多场景翻译的NVDA插件，支持数十种语言互译。该插件不仅仅支持针对NVDA读屏软件内容的手动翻译，还支持自动翻译、剪贴板内容翻译等功能。此外，插件具备源语言自动识别功能，开启后能自动判断源语言并将其翻译为目标语言。
            </p>
            <p>目前主要提供以下功能：</p>
            <ul>
              <li>
                翻译刚才听到的内容：针对读屏软件刚刚朗读的内容，按照在插件设置中的配置，从源语言翻译到目标语言。
              </li>
              <li>
                反向翻译刚才听到的内容：针对读屏软件刚刚朗读的内容，按照在插件中的翻译配置，从目标语言翻译到源语言。
              </li>
              <li>
                正向翻译剪贴板中的内容：针对剪贴板中的文本按照在插件设置中的配置，从源语言翻译到目标语言。
              </li>
              <li>
                反向翻译剪贴板中的内容：针对剪贴板中的文本，按照在插件中的翻译配置，从目标语言翻译到源语言。
              </li>
              <li>复制翻译结果到剪贴板：将翻译结果添加到剪贴板中。</li>
              <li>
                自动翻译模式：开启后，可将读屏软件朗读的所有内容自动进行翻译，包含“禁用”“正向”“反向”三种模式。
              </li>
            </ul>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1gWyyDHiUYXBaW9Sslk2M-A?pwd=zmxm"
                >点此百度网盘下载：百度翻译（NVDA读屏插件）</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="audioManager">
            <template #title>
              <h3>音频管理器（NVDA读屏插件）</h3>
            </template>
            <p>
              这是一个能够帮助你高效管理各种音频设备和应用音频路由的NVDA读屏插件。
            </p>
            <p>插件功能主要包括四大模块：</p>
            <ul>
              <li>
                播放设备控制：可针对播放设备进行音量调节、设置为系统默认设备、静音和解除静音。
              </li>
              <li>
                录音设备控制：可针对录音设备进行音量调节、设置为系统默认设备、静音和解除静音。
              </li>
              <li>
                应用音频控制：可针对每个音频应用指定不同的播放和录音设备、调节音量、静音和解除静音。
              </li>
              <li>
                其他实用功能：除上述功能外，还提供一系列实用功能，如麦克风音量锁定、NVDA读屏播放声卡切换等。
              </li>
            </ul>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1wNdLkGRgqqDkK09IrmHR4Q?pwd=r4id"
                >点此百度网盘下载：音频管理器（NVDA读屏插件）</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="webLocator">
            <template #title>
              <h3>网页控件定位器（NVDA读屏插件）</h3>
            </template>
            <p>
              这是一个NVDA读屏插件，能够通过快捷键快速聚焦和定位软件界面中的网页视图（WebView）。例如在PC微信的公众号界面中，进入后网页视图不会自动聚焦激活，导致NVDA读屏软件用户难以浏览其中的内容。通过本插件，结合其特有算法，可以快速查找并定位到界面中的网页视图，从而高效提升用户在包含网页视图软件中的操作效率。
            </p>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1MW74a0Ndj6MoTWQ5de71SQ?pwd=dbip"
                >点此百度网盘下载：网页控件定位器（NVDA读屏插件）</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="curProcInfo">
            <template #title>
              <h3>当前窗口进程信息（NVDA读屏插件）</h3>
            </template>
            <p>
              这是一个NVDA读屏插件，用于快速获取当前前台窗口所属进程的信息。通过简单的快捷键操作，用户可以迅速获得前台窗口的进程名称、ID和完整路径。对于那些窗口标题缺失的应用程序，这个功能特别有用，因为通过进程信息可以有效识别出相应的应用程序。
            </p>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1086n_qIoPbc3lS_YRb5fJQ?pwd=m1j1"
                >点此百度网盘下载：当前窗口进程信息（NVDA读屏插件）</a
              >
            </p>
          </el-collapse-item>
          <el-collapse-item name="xinyiTTS">
            <template #title>
              <h3>新翼语音开发包（SDK）</h3>
            </template>
            <p>
              本组件用于提供一致的TTS接口调用，包含CHSTTS、VVTTS以及微软SAPI
              5.1接口。组件所提供的函数符合Win
              SDK风格规范，所有与字符相关的函数都提供ANSI和Unicode两种风格调用，均在函数名尾部加以后缀字母“A”和“W”加以区分。
            </p>
            <p>组件特点：</p>
            <ul>
              <li>提供标准SDK与E模块两种调用方式，适用广泛。</li>
              <li>支持CHSTTS、VVTTS、微软5.1TTS接口。</li>
              <li>支持语音配置保存于载入功能。</li>
              <li>支持多声卡切换。</li>
              <li>支持排队朗读、回调朗读等高级模式。</li>
            </ul>
            <p>
              <a
                class="download-link"
                href="https://pan.baidu.com/s/1_NGMgcdT5oC1eWEPVH3C5A?pwd=fykd"
                >点此百度网盘下载：新翼语音开发包（SDK）</a
              >
            </p>
          </el-collapse-item>
        </el-collapse>
      </div>
    </el-main>
    <!--网页主体结束-->

    <!--网页底部开始-->
    <el-footer role="contentinfo">
      <div class="footer-content">
        <h2>联系方式</h2>
        <p>
          电子邮箱：
          <a href="mailto:contact@xytk.cn">contact@xytk.cn</a>
        </p>
        <p>
          <a href="https://beian.miit.gov.cn/" target="_blank"
            >粤ICP备2021058479号</a
          >
        </p>
      </div>
    </el-footer>
    <!--网页底部结束-->
  </el-container>
</template>

<script setup>
import { ref } from "vue";

const activateNames = ref(["XYMouseSimulator"]);
</script>

<style scoped>
/* 全局样式 */
body {
  margin: 0;
  font-family: Arial, sans-serif;
  background-color: #f4f4f4;
  color: #333;
}

/* 网页头部样式 */
.el-header {
  height: auto;
}

.header-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1e90ff;
  padding: 1.5rem;
  color: #fff;
  box-sizing: border-box;
}

.logo {
  margin-right: 1.5rem;
  padding: 0;
  border: 0;
}

.logo img {
  object-fit: contain;
  height: 60px;
}

.slogan {
  font-size: 1.5rem;
  line-height: 2;
  text-align: center;
  padding: 1.5rem;
  box-sizing: border-box;
}

/* 主体内容样式 */
.main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 1.5rem;
  font-size: 1.5rem;
  line-height: 2;
  box-sizing: border-box;
}

/* 手风琴区域样式 */
.el-collapse-item {
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 1rem;
  background-color: #fff;
}

.el-collapse-item .el-collapse-header {
  font-size: 1.2rem;
  color: #1e90ff;
  cursor: pointer;
  padding: 1rem;
  border-bottom: 1px solid #ddd;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.el-collapse-item .el-collapse-header:hover,
.el-collapse-item .el-collapse-header:focus {
  background-color: #1e90ff;
  color: #fff;
}

.el-collapse-item .el-collapse-header:focus {
  outline: 2px solid #1e90ff;
  outline-offset: 2px;
}

.el-collapse-item .el-collapse-content {
  padding: 1rem;
  font-size: 1rem;
  line-height: 1.6;
}

.el-collapse-content p {
  margin: 0 0 1rem 0;
}

/* 链接样式 */
.download-link {
  color: #1e90ff;
  text-decoration: none;
  font-weight: bold;
}

.download-link:hover {
  text-decoration: underline;
}

/* 网页底部样式 */
.el-footer {
  background-color: #fff;
  border-top: 1px solid #ddd;
  padding: 1.5rem;
  box-sizing: border-box;
}

.footer-content {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  font-size: 1.5rem;
  line-height: 2;
}

/* 响应式设计 */
@media (max-width: 470px) {
  h2 {
    font-size: 1.5rem;
  }

  .header-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    font-size: 1rem;
    padding: 1rem;
  }

  .logo {
    margin-right: 0;
    margin-bottom: 1rem;
  }

  .logo img {
    height: 40px;
    object-fit: contain;
  }

  .slogan {
    font-size: 1rem;
    line-height: 2;
  }

  .main-content {
    font-size: 1rem;
    padding: 1rem;
  }

  .footer-content {
    font-size: 1rem;
    padding: 1rem;
  }

  .el-collapse-item {
    margin-bottom: 0.5rem;
  }

  .el-collapse-item .el-collapse-header {
    font-size: 1rem;
    padding: 0.5rem;
  }

  .el-collapse-item .el-collapse-content {
    font-size: 0.9rem;
    padding: 0.5rem;
  }
}
</style>
